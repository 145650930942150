<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-list"></i>
          خطوط السير
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <th>العنوان</th>
              <th>ترتيب الطلاب</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="group in groups" :key="group._id">
                <td>
                  {{ group.title }}
                </td>
                <td>
                  <button
                    class="btn btn-success"
                    v-if="checkPer('groups-edit')"
                    v-b-modal.modal-1
                    @click="sortStudents(group._id)"
                  >
                    <i class="fa fa-users"></i>
                    ترتيب الطلاب
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-primary btn-sm"
                    v-if="checkPer('groups-edit')"
                    @click="$router.push('/groups/add-or-edit#' + group._id)"
                  >
                    <i class="fa fa-edit"></i>
                    تعديل
                  </button>
                  &nbsp;
                  <button
                    class="btn text-danger btn-sm"
                    v-if="checkPer('groups-delete')"
                    @click="deleteGroup(group)"
                  >
                    <i class="fa fa-trash"></i>
                    حذف
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="ترتيب الطلاب في خط السير" ok-only hide-footer>
      <h5>
        <i class="fa fa-sort-amount-desc"></i>
        يمكنك ترتيب الطلاب عن طريق الفأرة لأعلى ولأسفل.
      </h5>
      <div class="col-12 text-center g" v-if="loading">
        <img
          :src="require('@/assets/images/loading.svg')"
          style="width: 100px"
        />
      </div>
      <div class="col-12 g" v-if="!loading">
        <draggable
          v-model="students"
          group="people"
          @start="drag = true"
          @end="drag = false"
        >
          <div
            class="col-12 dragstudents"
            v-for="(student, index) in students"
            :key="student._id"
          >
            <span class="badge" style="border-radius: 0px; background: #bbb">{{
              index + 1
            }}</span>
            {{ student.name }}
          </div> </draggable
        ><br />
        <div class="col-12 g text-center">
          <button class="btn btn-primary" @click="saveStudents()">
            <i class="fa fa-check"></i>
            حفظ الترتيب
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BModal,
    draggable,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loading: false,
      students: [],
      user: JSON.parse(localStorage.getItem("user")),
      groups: [],
      group_id: null,
    };
  },
  created() {
    if (!checkPer("groups-view")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    $.post(api + "/user/groups/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = r.response;
      })
      .catch(function (e) {
        console.log(e);
        alert("حدث خطأ..");
      });
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    sortStudents(id) {
      var g = this;
      g.group_id = id;
      g.loading = true;
      $.post(api + "/user/students/list-names", {
        jwt: g.user.jwt,
        group_id: id,
      })
        .then(function (r) {
          g.loading = false;
          g.students = r.response;
        })
        .catch(function (e) {
          g.loading = false;
          alert("حدث خطأ..");
        });
    },
    deleteGroup(group) {
      var g = this;
      if (
        confirm("متأكد من حذف خط السير نهائياً؟ ربما يؤثر على باقي السجلات")
      ) {
        $.post(api + "/user/groups/delete", {
          jwt: g.user.jwt,
          id: group._id,
        })
          .then(function (r) {
            location.reload();
          })
          .catch(function (e) {
            console.log(e);
            alert("حدث خطأ..");
          });
      }
    },
    saveStudents() {
      var g = this;
      g.loading = false;
      $.post(api + "/user/groups/sort-students", {
        jwt: g.user.jwt,
        group_id: g.group_id,
        students: JSON.stringify(g.students),
      })
        .then(function (r) {
          g.loading = false;
          if (r.status == 100) {
            alert("تم الحفظ");
          } else {
            alert("حدث خطأ");
          }
        })
        .catch(function (e) {
          g.loading = false;
          alert("حدث خطأ..");
        });
    },
  },
};
</script>
<style>
.dragstudents {
  padding: 10px;
  background: #fff;
  border: 2px solid #eee;
  margin-top: 5px;
  cursor: move;
}
.dragstudents:hover {
  background: #ddd;
}
</style>